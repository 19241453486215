<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" class="design-iconfont" width="128" height="128">
  <defs>
    <path id="hbcroytsx__dsyx2e7t0a" d="M0 0H24V24H0z"></path>
  </defs>
  <g fill="none" fill-rule="evenodd">
    <mask id="hbcroytsx__iwk4bejjmb" fill="#fff">
      <use xlink:href="#hbcroytsx__dsyx2e7t0a"></use>
    </mask>
    <path stroke="#0F0629" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" d="M3.5 6L20.5 6"></path>
    <path stroke="#0F0629" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" d="M3.5 12L20.5 12"></path>
    <path stroke="#0F0629" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" d="M3.5 18L20.5 18"></path>
  </g>
</svg>
</template>