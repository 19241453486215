<template>
  <div class="seller" ref="sellerRef">
    <h3>Seller Application</h3>
    <a-form>
      <a-form-item 
        v-bind="validateInfos.companyName"
      >
        <div class="input">
          <span>Company Name</span>
          <a-input v-model:value="modelRef.companyName" />
        </div>
      </a-form-item>
      <a-form-item
        v-bind="validateInfos.websiteOrStorefront"
      >
        <div class="input" style="margin-top: 32px;">
          <span>Website or Amazon Storefront</span>
          <a-input v-model:value="modelRef.websiteOrStorefront"/>
        </div>
      </a-form-item>
      <a-form-item
        v-bind="validateInfos.referralSource"
      >
        <div class="input">
          <span>How did you hear about us?</span>
          <a-select
            v-model:value="modelRef.referralSource"
            style="width: 480px;"
            :options="HEAR_ABOUT_US_ARR"
          />
        </div>
      </a-form-item>
      <a-form-item v-bind="validateInfos.agreeTermsConditions">
        <div class="terms-conditions">
          <a-checkbox v-model:checked="modelRef.agreeTermsConditions">I agree to the <a>Terms & Conditions</a></a-checkbox>
        </div>
      </a-form-item>
    </a-form>
   
    <div class="amazon">
      <h3>Finish the sign up process by connecting your Amazon account.</h3>
      <p>Please connect using the email address with broad Seller/Vendor Central permissions to ensure all of your brands and products are imported correctly.</p>
      <div class="content">
          <a id="LoginWithAmazon" @click.prevent="onSubmit">
            <img border="0" alt="Login with Amazon"
                src="http://g-ecx.images-amazon.com/images/G/01/lwa/btnLWA_gold_312x64._CB372226922_.png"
                width="156" height="32" />
          </a>
        <a-select
          style="width: 160px"
          :getPopupContainer="() => sellerRef"
          v-model:value="amazonCountryCode"
        >
          <a-select-option value="US" label="US">
            <img class="country-icon" src="../../assets/images/svg/US.svg" />
            <span>US</span>
          </a-select-option>
          <a-select-option value="UK" label="UK">
            <img class="country-icon" src="../../assets/images/svg/UK.svg" />
            <span>UK</span>
          </a-select-option>
          <a-select-option value="CA" label="CA">
            <img class="country-icon" src="../../assets/images/svg/CA.svg" />
            <span>CA</span>
          </a-select-option>
          <a-select-option value="DE" label="DE">
            <img class="country-icon" src="../../assets/images/svg/DE.svg" />
            <span>DE</span>
          </a-select-option>
          <a-select-option value="FR" label="FR">
            <img class="country-icon" src="../../assets/images/svg/FR.svg" />
            <span>FR</span>
          </a-select-option>
        </a-select>
      </div>
    </div>
    <div id="amazon-root"></div>
  </div>
</template>
<script setup>
  import { ref, onMounted, reactive } from 'vue';
  import { useRoute } from 'vue-router';
  import { Form } from 'ant-design-vue';

  import { HEAR_ABOUT_US} from '../../contants/signUp';

  const HEAR_ABOUT_US_ARR = HEAR_ABOUT_US.map(item => ({
    label: item,
    value: item
  }));

  const sellerRef = ref();
  const useForm = Form.useForm;
  const props = defineProps(['goTo']);
  const route = useRoute();
  const amazonCode = route.query.code;

  const amazonCountryCode = ref('US');

  const modelRef = reactive({
    companyName: '',
    websiteOrStorefront: '',
    referralSource: '',
    agreeTermsConditions: false
  });

  const rulesRef = reactive({
    companyName: [{
      required: true,
      message: 'Company name cannot be empty',
    }],
    websiteOrStorefront: [{
      required: true,
      message: 'Must be a valid URL',
    }],
    referralSource: [{
      required: true,
      message: 'You must select an option',
    }],
    agreeTermsConditions: [{
      required: true,
      validator: validateAgreeTermsConditions,
      trigger: 'change',
    }],
  });

  const { validate, validateInfos } = useForm(modelRef, rulesRef);

  function validateAgreeTermsConditions  (_rule, value) {
    if (!value) {
      return Promise.reject('You must agree to the terms & conditions');
    } else {
      return Promise.resolve();
    }
  };

  onMounted(() => {
    amazonLoginInitialize();
  })

  function amazonLoginInitialize () {
    const CLIENT_ID = 'amzn1.application-oa2-client.d1775637c50f46d4b039895ab42f2dda';
    window.onAmazonLoginReady = function() {
      amazon.Login.setClientId(CLIENT_ID);
      // amazon.Login.logout();
    };
    (function(d) {
      var a = d.createElement('script'); a.type = 'text/javascript';
      a.async = true; a.id = 'amazon-login-sdk';
      a.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js';
      d.getElementById('amazon-root').appendChild(a);
    })(document);
  }

  function setProfile () {
    const params = {
      sellerProfile: {
        amazonAuthorizationCode: amazonCode,
        ...modelRef,
        amazonCountryCode: amazonCountryCode.value,
      },
      userType: 'seller'
    }
    localStorage.setItem('userProfile', JSON.stringify(params));
  }

  function onSubmit () {
    validate()
    .then(res => {
      if (!res.errorFields) {
        setProfile();
        const options = {};
        options.scope = 'advertising::campaign_management';
        options.response_type = 'code';
        // options.popup = false;
        amazon.Login.authorize(options, `${location.origin}/#/profile`);
        return false;
      }
    })
  }

</script>
<style lang="less" scoped>
  .seller{
    display: flex;
    flex-direction: column;
    > h3 {
      height: 36px;
      font-family: Sora-Medium;
      font-weight: 500;
      font-size: 28px;
      color: #0F0629;
      letter-spacing: 0.5px;
      line-height: 36px;
    }
    .input {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      width: 480px;
      > span {
        height: 16px;
        opacity: 0.7;
        font-family: Sora-Medium;
        font-weight: 500;
        font-size: 14px;
        color: #0F0629;
        letter-spacing: 0.4px;
        line-height: 16px;
        margin-bottom: 8px;
      }
      > input {
        height: 40px;
      }
    }
    .terms-conditions {
      margin-top: 24px;
      label {
        font-weight: 400;
      }
      span, a {
        color: #0f0629;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.4px;
        text-align: left;
      }
      a {
        text-decoration: underline;
      }
      
    }
    .amazon {
      margin-top: 40px;
      height: 150px;
      background: #FFFFFF;
      box-shadow: 0 1px 0 0 #1a16250d;
      border-radius: 8px;
      padding: 16px;
      > h3 {
        height: 18px;
        font-family: Sora-Medium;
        font-weight: 500;
        font-size: 13px;
        color: #0F0629;
        letter-spacing: 0.3px;
        line-height: 18px;
      }
      > p {
        margin-top: 4px;
        width: 448px;
        height: 48px;
        opacity: 0.7;
        font-family: Sora-Regular;
        font-weight: 400;
        font-size: 12px;
        color: #0F0629;
        letter-spacing: 0.3px;
        line-height: 16px;
      }
      .content {
        margin-top: 8px;
        display: flex;
        > a {
          width: 193px;
          height: 40px;
          border-radius: 2px;
          text-align: center;
          margin-right: 8px;
          > img {
            width: 193px;
            height: 40px;
          }
        }
        :deep(.ant-select-selector) {
          height: 40px;
          .ant-select-selection-item {
            display: flex;
            > img {
              width: 24px;
              margin-right: 8px;
            }
            > span {
              font-family: Sora-Regular;
              font-weight: 400;
              font-size: 16px;
              color: #0F0629;
              letter-spacing: 0.4px;
              line-height: 36px;
            }
          }
        }
      }
    }
    :deep(.ant-form) {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
    :deep(.ant-select-item-option-content) {
      display: flex;
      > img {
        width: 22px;
        height: 16px;
        align-self: center;
        margin-right: 12px;
      }
    }
  }
  
</style>