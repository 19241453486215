<template>
  <div class="top-bar" ref="topBarRef">
    <menu2CuSvg />
    <div class="user-info">
      <a-dropdown :getPopupContainer="() => topBarRef">
        <wodi3svg />
        <template #overlay>
          <a-menu>
            <a-menu-item key="0" disabled>
              <div class="title">
                <!-- <h3>{{userInfo.userName || ''}}</h3>
                <p>{{userInfo.email || ''}}</p> -->
                <h3>{{store.userInfo?.userName || ''}}</h3>
                <p>{{store.userInfo?.email || ''}}</p>
              </div>
            </a-menu-item>
            <a-menu-item key="1">
              <div class="select-item">
                <div class="sign-out" @click="onSignOut">
                  <tuichu />
                  <span>Sign Out</span>
                </div>
              </div>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>

<script setup>
  import menu2CuSvg from '../components/svg/menu2-cu.vue';
  import tuichu from '../components/svg/tuichu.vue';
  import wodi3svg from '../components/svg/wodi3.vue';
  import { ref } from 'vue';
  import { useUserInfoStore } from '../storeData/index';

  import { signApi } from '../server/index'

  const store = useUserInfoStore();
  const topBarRef = ref();
  const onSignOut = async () => {
      const res = await signApi.signOut();
      const { success, error } = res;
      if (success) {
        store.setUserInfo('');
        location.href = '/#/sign-in';
      } else {
        console.log(error);
      }
  }

</script>
<style lang="less" scoped>
  @media screen and (min-width: 960px) {
    .top-bar {
      width: 100%;
      height: 48px;
      background: #FFFFFF;
      box-shadow: 0 1px 0 0 #ECE6F2;
      display: flex;
      justify-content: space-between;
      padding-left: 27px;
      padding-right: 24px;
      > svg {
        width: 24px;
        height: 24px;
        align-self: center;
      }
      .user-info {
        flex: 1;
        display: flex;
        justify-content: right;
        > svg {
          width: 29px;
          height: 29px;
          align-self: center;
          cursor: pointer;
        }
      }
      .title {
        width: 256px;
        padding: 16px 23px 12px 23px;
        border-bottom: 1px solid #E8E5ED;
        > h3 {
          opacity: 0.5;
          font-family: Sora-Bold;
          font-weight: 700;
          font-size: 14px;
          color: #0F0629;
          letter-spacing: 0.4px;
          line-height: 19px;
        }
        > p {
          margin-top: 2px;
          opacity: 0.5;
          font-family: Sora-Regular;
          font-weight: 400;
          font-size: 13px;
          color: #0E0528;
          letter-spacing: 0.4px;
          line-height: 18px;
        }
      }
      .select-item {
        padding: 8px 8px 0 8px;
         span {
          height: 19px;
          opacity: 0.7;
          font-family: Sora-Regular;
          font-weight: 400;
          font-size: 14px;
          color: #0F0629;
          letter-spacing: 0.4px;
          line-height: 19px;
          align-self: center;
         }
      }
      .sign-out {
        cursor: pointer;
        display: flex;
        > svg {
          width: 16px;
          height: 16px;
          margin-right: 12px;
          margin-left: 8px;
          align-self: center;
        }
        padding-bottom: 8px;
      }
    }
  }
  
@media screen and (max-width: 479px) {
  .top-bar {
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    box-shadow: 0 1px 0 0 #ECE6F2;
    display: flex;
    justify-content: space-between;
    padding-left: 27px;
    padding-right: 24px;
    > svg {
      width: 24px;
      height: 24px;
      align-self: center;
    }
    .user-info {
      flex: 1;
      display: flex;
      justify-content: right;
      > svg {
        width: 29px;
        height: 29px;
        align-self: center;
        cursor: pointer;
      }
    }
    .title {
      width: 256px;
      padding: 16px 0 12px 23px;
      > h3 {
        opacity: 0.5;
        font-family: Sora-Bold;
        font-weight: 700;
        font-size: 14px;
        color: #0F0629;
        letter-spacing: 0.4px;
        line-height: 19px;
      }
      > p {
        margin-top: 2px;
        opacity: 0.5;
        font-family: Sora-Regular;
        font-weight: 400;
        font-size: 13px;
        color: #0E0528;
        letter-spacing: 0.4px;
        line-height: 18px;
      }
    }
  }
}
</style>
