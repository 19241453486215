
export const HEAR_ABOUT_US = [
  "Ads",
  "Agency",
  "Already a customer",
  "Amazon Community",
  "Amazon Partner Listing",
  "Blog Post",
  "Capterra",
  "Email",
  "Event/Tradeshow",
  "Google search or other search engine",
  "Industry Expert Referral",
  "Newsletter",
  "Podcast",
  "Press or other new article",
  "Slack Group",
  "Social Media",
  "Webinar",
  "Word of Mouth",
  "YouTube",
  "Other",
]

export const SIGN_COUNTRY = [
  "US",
  "CA",
  "UK",
  "DE",
  "FR",
]
