<template>
  <div class="select-user-type">
    <h3>Welcome to ArtemisAds</h3>
    <p>Are you a Seller or a Creator?</p>
    <div class="seller" @click="props.goTo(1)">
      <div class="icon">

      </div>
      <div class="text">
        <span>Seller</span>
        <span>I have an Amazon Seller or Vendor account</span>
      </div>
    </div>
    <div class="creator">
      <div class="icon">

      </div>
      <div class="text">
        <span>Creator</span>
        <span>I want to promote Amazon products</span>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { onMounted } from "vue";

  const props = defineProps(['goTo']);

</script>

<style lang="less" scoped>
  .select-user-type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    > h3 {
      height: 36px;
      font-family: Sora-Medium;
      font-weight: 500;
      font-size: 28px;
      color: #0F0629;
      letter-spacing: 0.5px;
      line-height: 36px;
    }
    > p {
      height: 20px;
      font-family: Sora-Regular;
      font-weight: 400;
      font-size: 14px;
      color: #6A647D;
      letter-spacing: 0.4px;
      line-height: 20px;
      margin-top: 8px;
    }
    .seller, .creator {
      width: 424px;
      height: 72px;
      padding-top: 15px;
      padding-left: 16px;
      background: #FFFFFF;
      border: 1px solid #CCC7D9;
      box-shadow: 0 1px 0 0 #1a16250d;
      border-radius: 8px;
      display: flex;
      cursor: pointer;
      .icon {
        width: 40px;
        height: 40px;
        background: #FF9618;
        border-radius: 20px;
      }
      .text {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 16px;
        > span {
          &:first-child {
            height: 26px;
            font-family: Sora-Medium;
            font-weight: 500;
            font-size: 18px;
            color: #0F0629;
            letter-spacing: 0.5px;
            line-height: 26px;
          }
          &:last-child {
            height: 20px;
            opacity: 0.7;
            font-family: Sora-Regular;
            font-weight: 400;
            font-size: 14px;
            color: #0F0629;
            letter-spacing: 0.4px;
            line-height: 20px;
          }
        }
      }
    }
    .seller {
      margin-top: 32px;
      .icon {
        background: #8F6AF7;
      }
    }
    .creator {
      margin-top: 12px;
      .icon {
        background: #FF9618;
      }
    }
  }
</style>