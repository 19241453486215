<template>
  <div class="sign-left-cont">
    <div class="sign-left-wrap">
      <img class="artemisAds-logo" src="../assets/images/sign/artemisAds_logo@2x.png" />
      <div class="main-title">
        <div>Your <span class="growth" style='color: #FFC502'>Growth</span></div>
        <div>Our <span class="expertise">Expertise</span></div>
      </div>
      <p class="main-text" style='margin-top: 40px'>
        From clicks to sales, we’ve got you covered. ArtemisAds’ stellar multi-channel marketing services help elevate your brands, and boost your sales!
      </p>
      <p class="main-text" style='margin-top: 24px'>
        ArtemisAds implement and manage analytics, and enable clients to gain greater insights in their business and enhance decision-making capabilities
      </p>
      <img class="amazon-logo" src="../assets/images/sign/amazon-logo@2x.png" />
      <img class="sign-bg" src="../assets/images/sign/sign-left-bg.png" />
      <img class="sign-bg-wap" src="../assets/images/sign/sign-left-bg-wap.png" />
    </div>
  </div>
</template>
<script setup>
  
</script>
<style lang="less" scoped>
  @media screen and (min-width: 960px) {
    .sign-left-cont {
      position: relative;
      width: 33.333vw;
      min-width: 480px;
      height: 100%;
      background: #F4F2FA;
      .sign-left-wrap {
        height: 100%;
        padding: 9vh 2.5vw 0 3vw;
        background-image: linear-gradient(149deg, #5436E9 0%, #A683FC 100%);
        border-radius: 0 0 160px 0;
      }
      .artemisAds-logo {
        width: 246px;
        height: 56px;
      }
      .main-title {
        margin-top: 61px;
        color: #ffffff;
        font-size: 47px;
        font-weight: 700;
        line-height: 63px;
        letter-spacing: 0.87px;
        text-align: left;
        .growth {
          display: inline-block;
          padding-right: 8px;
          background-image: url('../assets/images/sign/growth@2x.png');
          background-position: 0 45px;
          background-repeat: no-repeat;
          background-size: 100%;
          line-height: 63px;
        }
        .expertise {
          display: inline-block;
          background-image: url('../assets/images/sign/expertise@2x.png');
          background-position: 0 55px;
          background-repeat: no-repeat;
          background-size: 100%;
          line-height: 68px;
          padding-right: 8px;
        }
      }
      .main-text {
        width: 400px;
        height: 104px;
        font-family: Sora-Regular;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 0.4px;
        line-height: 26px;
        margin-bottom: 0;
      }
      .amazon-logo {
        position: absolute;
        bottom: 5vh;
        width: 242px;
        height: 42px;
        margin-top: 109px;
      }
      .sign-bg {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 216px;
        height: 294px;
        opacity: 0.8;
      }
    }
  }
  
  @media screen and (max-width: 479px) {
    .sign-left-cont {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100dvw;
      height: 100%;
      background-image: linear-gradient(149deg, #5436E9 0%, #A683FC 100%);
      .sign-left-wrap {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 9vh 2.5vw 0 3vw;
        border-radius: 0 0 160px 0;
      }
      .artemisAds-logo {
        width: 210px;
        height: 48px;
        align-self: center;
      }
      .main-title, .main-text, .amazon-logo {
        display: none;
      }
      .sign-bg-wap {
        position: absolute;
        top: 0;
        left: 0;
        width: 216px;
        height: 294px;
        opacity: 0.8;
      }
      .sign-bg {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 216px;
        height: 294px;
        opacity: 0.8;
      }
    }
    
  }
</style>
