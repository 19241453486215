<template>
  <div class="profile-up">
    <div class="profile-left">
      <signInUpLeft />
    </div>
    <div class="profile-right">
      <div class="profile-wrap">
        <topBar/>
        <div class="profile-up-content">
           <a-carousel effect="fade" 
            ref="myCarousel"
            dot-position="top"
            :afterChange="afterChange"
          >
            <div class="carousel-item">
              <selectUserType :goTo="goTo"/>
            </div>
            <div class="carousel-item">
              <seller :goTo="goTo"/>
            </div>
            <div class="carousel-item">
              <connecting :goTo="goTo"/>
            </div>
            <div class="carousel-item">
              <h3></h3>
            </div>
          </a-carousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { ref } from 'vue';
  import jwt from 'jsonwebtoken';

  import { useUserInfoStore } from '../../storeData/index';
  import { profileApi } from '../../server/index'
  import signInUpLeft from '../../components/signInUpLeft.vue'
  import topBar from '../../components/topBar.vue';
  import selectUserType from './selectUserType.vue'
  import seller from './seller.vue'
  import connecting from './connecting.vue'
  
  export default {
    setup() {
      const myCarousel = ref(null);  
      const store = useUserInfoStore();
      return {
          myCarousel,
          store
      }
    },
    components: {
      signInUpLeft,
      selectUserType,
      topBar,
      seller,
      connecting
    },
    methods: {
      goTo(number) {
        this.$refs.myCarousel.goTo(number);
      },
      afterChange (current) {
        const $slickSots = document.querySelectorAll('.slick-dots li')
        $slickSots.forEach(($item, index) => {
          if (index < current) {
            $item.classList.add('slick-active');
          }
        })
      },
      async userActivate (activateToken) {
        const params = {
          activateToken
        }
        const res = await profileApi.userActivate(params);
        const { data, success, error } = res;
        console.log(res);
        if (success) {
          const userToken = data.token;
          const artmisAdsUserData = jwt.decode(userToken);
          this.store.setUserInfo(artmisAdsUserData, userToken);
          if (artmisAdsUserData.status !== 'PENDING_CHOOSE_TYPE') {
            location.href = '/#/admin';
          }
        } else {
          location.href = '/#/sign-in';
        }
      },
      checkPage () {
        const { code, token } = this.$route.query;
        const userToken = localStorage.getItem('userToken');
        if (code) {
          this.goTo(2);
        } else if (token) {
          this.userActivate(token)
        } else if (!userToken) {
          location.href = '/#/sign-in';
        }
      }
    },
    updated () {
      this.checkPage();
    },
    mounted () {
      this.checkPage();
    }
  }

</script>
<style lang="less" scoped>
@media screen and (min-width: 960px) {
  .profile-up {
    width: 100vw;
    height: 100vh;
    min-height: 800px;
    overflow: hidden;
    display: flex;
    :deep(.ant-carousel) {
      width: 500px;
    }
  }
  .profile-right {
    width: 66.666vw;
    display: flex;
    justify-content: center;
    background: #F4F2FA;
    flex: 1;
  }
  .profile-wrap {
    width: 100%;
  }
  .profile-up-content {
    margin-top: 8vh;
    width: 100%;
    display: flex;
    justify-content: center;
    .carousel-item {
      padding-top: 60px;
    }
  }
  :deep(.slick-dots) {
    justify-content: left;
    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 20px;
      top: -10px;
      background-color: transparent;
      z-index: 1;
    }
    li button {
      background: #7D58E5;;
    }
    
    li, button, .slick-active {
      width: 56px !important;
    }
    .slick-active button {
      background: #7D58E5 !important;
    }
  }
}

@media screen and (max-width: 479px) {
  .profile-right {
    position: relative;
    width: 100dvw;
    height: auto;
    min-height: 100dvh;
    display: flex;
    overflow: scroll;
  }
  .profile-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    .profile-up-content {
      margin-top: 120px;
      align-self: center;
      position: relative;
      z-index: 1;
      width: calc(100dvw - 32px);
      padding: 24px 32px;
      width: 343px;
      background: #FFFFFF;
      box-shadow: 0 1px 0 0 #1a16250d;
      border-radius: 16px;
    }
  }
}
  
</style>
